<template>
	<w-data-table :headers="headers" :items="items" :loading="loadings > 0" :pagination="pagination" @update:pagination="updatePagination">
		<template v-slot:items="{ item }">
			<td v-text="item.title" />
			<td>
				<v-chip :class="getTextColor(item.status.color)" :color="item.status.color" small>{{ $t(item.status.name) }}</v-chip>
			</td>
			<td v-text="item.due_date ? $d(new Date(item.due_date), 'tiny') : undefined" />
			<td v-text="$d(new Date(item.created_at), 'tiny')" />
		</template>
	</w-data-table>
</template>

<script>
import ProjectsManagerModuleGuard from '@/mixins/ModulesGuards/Offers/ProjectsManagerModuleGuard'
import Service from '@/classes/Offers/Service'

import PaginationMixin from '@/mixins/PaginationMixin'

export default {
	name: 'TasksList',
	mixins: [ProjectsManagerModuleGuard, PaginationMixin],
	props: {
		value: {
			required: true,
			type: Service
		}
	},
	data: function () {
		return {
			defaultPaginationData: {
				page: 1,
				rowsPerPage: 5,
				totalItems: 0
			},
			cancelToken: null,
			headers: [
				{
					sortable: true,
					text: this.$t('name'),
					value: 'title'
				},
				{
					sortable: false,
					text: this.$t('client_workspace.task.status')
				},
				{
					sortable: true,
					text: this.$t('due_date'),
					value: 'due_date'
				},
				{
					sortable: true,
					text: this.$t('creation_date'),
					value: 'created_at'
				}
			],
			items: [],
			loadings: 0,

			onPaginationUpdated: this.listTasks
		}
	},
	watch: {
		value: {
			deep: true,
			handler: function (newVal, oldVal) {
				if (oldVal?.id && newVal?.id && newVal?.id !== oldVal?.id) {
					this.resetPagination()
					this.listTasks()
				}
			}
		}
	},
	methods: {
		getTextColor: function (color) {
			return this.$getContrastedTextColor(color)
		},
		listTasks: function () {
			this.loadings++
			const params = {
				accounting_firm_id: this.accountingFirmId,
				company_id: this.vendorId,
				only_soon_to_ending_or_unfinished: true,
				page: this.pagination.page,
				po: this.pagination.rowsPerPage,
				service_id: this.value.id
			}
			const serviceTasks = this.service.listServiceTasks(params, this.cancelToken)
			const callAnswer = {
				cancelToken: serviceTasks.cancelToken,
				promise: serviceTasks.promise
					.then(res => res.data)
					.then(({ data, pagination }) => {
						return {
							paginationData: pagination,
							tasks: data
						}
					})
			}
			this.cancelToken = callAnswer.cancelToken
			return callAnswer.promise
				.then(({ paginationData, tasks }) => {
					this.items = tasks
					this.$store.dispatch('customers/projects/setPagination', paginationData)
				})
				.finally(() => {
					this.loadings--
				})
		},
		updatePagination: function (paginationData) {
			Object.assign(this.pagination, paginationData)
			this.listTasks()
		}
	}
}
</script>
